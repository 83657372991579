<template>
  <div>
    <b-dropdown id="dropdown-1" class="multi-select-dropdown m-0 p-0" offset="25" no-caret no-flip style="height: 20px;">
      <template #button-content>
        <span v-if="firstBrand">
          <span v-for="(item, index) of tagItems" :key="index" class="selected-tag">
            {{ item.title }}
          </span>
        </span>

        {{ countTag }}
      </template>

      <div class="car-brands-options" style="max-height: 200px; width: 190px; overflow-y: scroll;">
        <div v-for="(carBrand, i) in options" :key="i" class="car-brand-menu-item" :class="{ 'selected': selectedFields.includes(carBrand._id) }" @click="selectCarBrand(carBrand)">
          <b-avatar
            size="40"
            :src="getValueFromSource(carBrand, 'logo.path')"
            variant="info"
            style="background-color: #F0F0F0; padding: 2px; object-fit: contain;"
            alt="Brand Image"
            class="brand-drop-down-image"
          >
          </b-avatar>
          {{ carBrand.title }}
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BRow,
  BCol,
  BImg,
  BLink,
  BCard,
  BButton,
  BOverlay,
  BCardText,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { truncate, startCase } from "lodash";


export default {
  name: "MultiSelectDropdown",
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BButton,
    BOverlay,
    BCardText,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    maxTags: {
      type: Number,
      default: 2,
    },
    emptyText: {
      type: String,
      default: "All",
    },
  },
  data() {
    return {
      selectedFields: []
    }
  },
  computed: {
    textLabel() {
      const size = this.selectedFields.length
      if (size === 0) {
        return "(0) items";
      }

      const first = this.selectedFields[0];
      const truncatedFirst = startCase(truncate(first, {
        length: 15,
      }))

      let formatted = truncatedFirst;
      if (size > 1) {
        formatted = `${formatted} +${size - 1} more`
      }

      return formatted;
    },
    countTag() {
      const size = this.selectedFields.length
      if (size === 0) {
        return this.emptyText;
      }
      if (size === 1) {
        return "";
      }

      const remainder = size - this.maxTags
      return remainder ? `+${remainder} more` : '';
    },
    firstTag() {
      const size = this.selectedFields.length
      if (size === 0) {
        return null;
      }
      return startCase(this.selectedFields[0]);
    },
    firstBrand() {
      if (this.selectedFields.length === 0) {
        return null;
      }
      return this.options.find(option => option._id === this.selectedFields[0])
    },
    selectedItems() {
      return this.options.filter((option) => this.selectedFields.includes(option._id));
    },
    tagItems() {
      return this.selectedItems.slice(0, this.maxTags);
    }
  },
  watch: {
    selectedFields(newVal) {
      this.$emit('input', newVal)
    }
  },
  mounted() {
    this.selectedFields = Array.isArray(this.value) ? this.value : [];
  },
  methods: {
    selectCarBrand(item) {
      const index = this.selectedFields.indexOf(item._id);
      if (index > -1) {
        this.selectedFields.splice(index, 1);
      } else {
        this.selectedFields.push(item._id);
      }
    },
    getItem(id) {
      return this.options.find((option) => option._id === id);
    }
  }
};
</script>

<style>
div.multi-select-dropdown > button.btn.dropdown-toggle.btn-secondary.dropdown-toggle-no-caret {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  padding: 0;
  margin: 0;
}

div.multi-select-dropdown .btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: none !important;
}

div.multi-select-dropdown .selected-tag {
  padding: 4px 10px;
  border: 1px solid #CCCBCB;
  background-color: #FAFAFA;
  border-radius: 6px;
  margin-right: 5px;
}

div.multi-select-dropdown .selected-tag + div.multi-select-dropdown .selected-tag {
  margin-left: 5px;
}

.car-brand-menu-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 0px;
  cursor: pointer;

  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 6px;
}

.car-brand-menu-item:hover, .car-brand-menu-item.selected {
  background-color: #CCCBCB;
}
</style>
