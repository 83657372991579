<template>
  <div>
    <b-dropdown id="dropdown-1" class="multi-select-dropdown m-0 p-0" offset="25" no-caret no-flip style="height: 20px;">
      <template #button-content>
        <span v-if="firstTag" class="selected-tag">{{ firstTag }}</span>
        {{ countTag }}
      </template>

      <b-form-group label="" class="p-1 w-100 w-full" style="max-height: 200px; width: 190px; overflow-y: scroll;">
        <b-form-checkbox-group
          v-model="selectedFields"
          name="car-types"
          class="demo-inline-spacing"
        >
          <b-row>
            <b-col v-for="(carType, i) in options" :key="i" cols="6" md="12" class="mb-1">
              <b-form-checkbox :value="carType._id || ''" class="text-dark">
                <span class="text-dark">
                  {{ carType.title }} <span v-if="showCount" class="text-muted">({{ carType.cars_count }})</span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BRow,
  BCol,
  BImg,
  BLink,
  BCard,
  BButton,
  BOverlay,
  BCardText,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { truncate, startCase } from "lodash";


export default {
  name: "MultiSelectDropdown",
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BButton,
    BOverlay,
    BCardText,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: "All",
    },
  },
  data() {
    return {
      selectedFields: []
    }
  },
  computed: {
    textLabel() {
      const size = this.selectedFields.length
      if (size === 0) {
        return this.emptyText;
      }

      const first = this.selectedFields[0];
      const truncatedFirst = startCase(truncate(first, {
        length: 15,
      }))

      let formatted = truncatedFirst;
      if (size > 1) {
        formatted = `${formatted} +${size - 1} more`
      }

      return formatted;
    },
    countTag() {
      const size = this.selectedFields.length
      if (size === 0) {
        return this.emptyText;
      }
      if (size === 1) {
        return "";
      }
      return `+${size - 1} more`;
    },
    firstTag() {
      const size = this.selectedFields.length
      if (size === 0) {
        return "";
      }
      const target = this.options.find((item) => item._id === this.selectedFields[0])
      return startCase(target.title);
    }
  },
  watch: {
    selectedFields: {
      deep: true,
      handler(newVal) {
      // console.log("new VAl", newVal)
      // const selected = this.selectedFields.filter((item) => newVal.includes(item))
      this.$emit('input', newVal)
    }
    }
  },
  mounted() {
    this.selectedFields = Array.isArray(this.value) ? this.value : [];
  },
};
</script>

<style>
div.multi-select-dropdown > button.btn.dropdown-toggle.btn-secondary.dropdown-toggle-no-caret {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  padding: 0;
  margin: 0;
}

div.multi-select-dropdown .btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: none !important;
}


div.multi-select-dropdown .selected-tag {
  padding: 4px 10px;
  border: 1px solid #CCCBCB;
  background-color: #FAFAFA;
  border-radius: 6px;
}
</style>
