<template>
  <div class="">
    <b-overlay :show="loading">
      <div class="text-center d-flex flex-column" style="gap: 30px">
        <div>
          <app-logo width="148px" />
        </div>
        <h1 class="text-24 mt-5">Rental Successful</h1>
        <div class="my-2 h-48">
            <b-img
              fluid
              src="@/assets/gifs/payment-success.gif"
              alt="Rental Success"
            />
        </div>
      </div>
      <b-alert variant="danger" show>
        <div v-if="error" class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <small>{{ error }}</small>
          </b-card-text>
        </div>
      </b-alert>

      <b-button
        class="py-1"
        type="submit"
        variant="primary"
        block
        :to="{ name: 'my-rentals' }"
      >
        View Rentals
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import SocialAuth from "@/@core/components/shared/SocialAuth.vue";

import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import AppLogo from "@core/layouts/components/Logo.vue";

import {
  BImg,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  VBTooltip,
} from "bootstrap-vue";
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    SocialAuth,
    AuthWrapper,
    BAlert,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    // BAlert,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      error: "",
      remember_me: "",
      password: "",
      username: "",
      // validation rules
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import "@core/scss/vue/pages/page-public.scss";

sup {
  font-size: 1rem;
}

.text-24 {
  color: #151314;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
