<template>
  <div id="home-page">
    <section>
      <b-jumbotron ref="section1" :style="backgroundImageStyle" class="px-4">
        <div class="" style="margin-top: 150px;">
          <b-row>
            <b-col cols="12" md="4" sm="12" class="p-0">
              <h1 class="display-4 font-weight-bolder">
                Find the car for your stay in Ghana.
              </h1>
    
              <p class="text-white">
                If you need to visit Ghana and need the car thats perfect for you? We've got you covered. Ristic cars offers the best rides and rates
              </p>
            </b-col>

            <b-col cols="12" class="p-0  mt-3">
              <mobile-view-search-input />
            </b-col>
          </b-row>
        </div>
        
        <b-row>
          <b-col cols="12" class="p-0">
            <b-card class="d-none d-md-block">
              <b-row class="d-flex align-items-center justify-content-center">
                <b-col cols="12" md="2" style="">
                  <custom-field-wrapper title="Car Brands">
                    <MultiSelectCarBrandDropdown v-model="localFilter.car_brands" :options="carBrands" />
                  </custom-field-wrapper>
                </b-col>
    
                <b-col cols="12" md="2">
                  <custom-field-wrapper title="Car Type">
                    <MultiSelectDropdown v-model="localFilter.car_types" :show-count="true" empty-text="All" :options="carTypes" />
                  </custom-field-wrapper>
                </b-col>
    
                <b-col cols="12" md="2">
                  <custom-field-wrapper title="Car Features">
                    <MultiSelectDropdown v-model="localFilter.car_features" :options="carFeatures" empty-text="All" />
                  </custom-field-wrapper>
                </b-col>
    
                <b-col cols="12" md="2">
                  <custom-field-wrapper title="Minimum Price ($)" :caret="false">
                    <div style="height: 20px; display: flex; align-items: flex-end;">
                      <b-form-input v-model="localFilter.price_range[0]" type="number" class="m-0 p-0" />
                    </div>
                  </custom-field-wrapper>
                </b-col>
    
                <b-col cols="12" md="2">
                  <custom-field-wrapper title="Maximum Price ($)" :caret="false">
                    <div style="height: 20px; display: flex; align-items: flex-end;">
                      <b-form-input v-model="localFilter.price_range[1]" type="number" class="m-0 p-0" />
                    </div>
                  </custom-field-wrapper>
                </b-col>
    
                <b-col cols="12" md="2" class="d-flex align-items-center justify-content-end">
                  <b-button variant="primary" href="#" size="md" @click="searchCars">
                    <feather-icon icon="SearchIcon" size="16" class="text-white" />
                    <span class="ml-1">Search</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        
      </b-jumbotron>
    </section>

    <section ref="section2" class="fade-in">
      <div class="px-3 my-5">
        <custom-scroll-wrapper 
          title="Cars to rent" 
        >
          <swiper-slide
            v-for="(car, i) in carsAvailableForDisplay" 
            :key="i"
            class="rounded swiper-shadow"
          >
            <car-display-card 
              :car="car" 
              :is-favorite="isFavoriteCar(car._id)"
              @add-favorite="$car_id => onAddFavorite($car_id)"
              @remove-favorite="$car_id => onRemoveFavorite($car_id)"
            />
          </swiper-slide>
        </custom-scroll-wrapper>
      </div>
    </section>

    <section ref="section3" class="fade-in">
      <div class="px-3 my-5">
        <b-row class="mb-3">
          <b-col cols="12" md="4" class="p-0">
            <h1 class="font-weight-bolder text-dark mb-2">
              Our services
            </h1>
          </b-col>
  
          <b-col cols="12" md="8">
            <p class="text-muted">
              Lorem ipsum dolor sit amet consectetur. Sed nisi risus viverra amet non. Diam tristique ac diam fames sollicitudin. Arcu enim mauris tristique tempor. Lectus faucibus nec eu quam aliquet et. 
            </p>
          </b-col>
        </b-row>
  
        <b-row>
          <b-col v-for="(service, i) in services" :key="i" cols="12" md="4" class="m-0 p-0">
            <b-card no-body class="overflow-hidden" bg-variant="light-primary" style="max-width: 540px;">
              <b-row no-gutters>
                <b-col md="4">
                  <b-card-img :src="service.image" alt="Image" class="rounded-0" />
                </b-col>
  
                <b-col md="8">
                  <b-card-body>
                    <h4 class="text-dark font-weight-bold">{{ service.title }}</h4>
  
                    <b-card-text class="text-muted">
                      {{ service.description }}
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </section>

    <section>
      <div ref="section4" class="overflow-hidden fade-in">
        <why-choose-us />
      </div>
    </section>

    <section v-if="bestOfferCar" ref="section6" class="fade-in">
      <div class="d-flex align-items-center justify-content-center my-5 md-mb-200">
        <best-car-of-the-day-ad 
          :best-offer="bestOfferCar"
        />
      </div>
    </section>

    <section v-else></section>

    <section ref="section7" class="testimonial-section fade-in">
      <div class="px-3 my-5">
        <custom-scroll-wrapper 
          title="What people say about us" 
        >
          <swiper-slide
            v-for="(testimonial, i) in testimonials"
            :key="i"
            class="rounded swiper-shadow"
          >          
            <b-card class="mt-1" style="min-height: 280px;">
              <b-img :src="require(`@/assets/images/testimonials/quote.png`)" style="width: 42px; height: 34px" />
              <h5 class="text-dark font-weight-bolder mt-2">{{ testimonial.title }}</h5>

              <b-card-text class="">
              {{ testimonial.text }}
              </b-card-text>

              <div class="d-flex align-items-center justify-content-start">
                <b-avatar variant="info" :src="testimonial.image" class="mr-2" />
                <div>
                  <h5 class="p-0 m-0 text-dark">{{ testimonial.name }}</h5>
                  <feather-icon v-for="n in 5" :key="n" icon="StarIcon" class="star-icon" />
                </div>
              </div>
            </b-card>
          </swiper-slide>
        </custom-scroll-wrapper>
      </div>
    </section>

    <app-side-bar id="booking-success-sidebar" :visible="authUIState.bookingSuccess">
      <booking-success />
    </app-side-bar>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BLink,
  BAvatar,
  BButton,
  BOverlay,
  BCardImg,
  BCardText,
  BCardBody,
  BCarousel, 
  BCardHeader,
  BJumbotron,
  BCarouselSlide,
  BFormInput,
} from 'bootstrap-vue';

import vSelect from 'vue-select'

import { SwiperSlide } from 'vue-awesome-swiper'

import CarsMixin from "@/@core/mixins/cars";
import HeroImage from '@/assets/images/public/hero-home.png';
import AppSideBar from '@/@core/components/shared/AppSideBar.vue';
import WhyChooseUs from '@/@core/components/shared/WhyChooseUs.vue';
import BookingSuccess from '@core/components/shared/BookingSuccess.vue';
import CarDisplayCard from '@/@core/components/shared/CarDisplayCard.vue';
import BestCarOfTheDayAd from '@/@core/components/shared/BestCarOfTheDayAd.vue';
import CustomFieldWrapper from '@/@core/components/shared/CustomFieldWrapper.vue';
import MultiSelectDropdown from '@/@core/components/shared/MultiSelectDropdown.vue'
import CustomScrollWrapper from '@/@core/components/shared/CustomScrollWrapper.vue';
import MobileViewSearchInput from '@/@core/components/shared/MobileViewSearchInput.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MultiSelectCarBrandDropdown from '@/@core/components/shared/MultiSelectCarBrandDropdown.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BAvatar,
    BButton,
    vSelect,
    BCardImg,
    BOverlay,
    BCardBody,
    BCardText,
    BCarousel,
    BJumbotron,
    BCardHeader,
    BFormInput,
    CarDisplayCard,
    BCarouselSlide,
    MultiSelectDropdown,
    MobileViewSearchInput,
    MultiSelectCarBrandDropdown,

    AppSideBar,
    WhyChooseUs,
    SwiperSlide,
    BookingSuccess,
    BestCarOfTheDayAd,
    CustomFieldWrapper,
    CustomScrollWrapper
  },
  mixins: [CarsMixin],
  data() {
    return {
      loading: false,
      heroImage: HeroImage,
      localFilter: {
        car_types: [],
        car_brands: [],
        car_features: [],
        price_range: [0, 0],
      },
      services: [
        {
          title: 'Airport Transport',
          description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/services/image-1.png`),
        },
        {
          title: 'Intercity Trips',
          description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/services/image-2.png`),
        },
        {
          title: 'Wedding Events',
          description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/services/image-3.png`),
        },
        {
          title: 'Business Meetings',
          description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/services/image-4.png`),
        },
        {
          title: 'Trips',
          description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/services/image-5.png`),
        },
        {
          title: 'Visits',
          description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/services/image-6.png`),
        }
      ],
      testimonials: [
        {
          title: 'It proved to be exactly the kind of home we wanted.',
          text:
            'We wish to express our thanks for your hard work in finding us a temporary home, which proved to be exactly what we wanted.',
          name: 'Jaydon Aminoff',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-1.png`),
        },
        {
          title: 'Nobody knows Portland and the peninsula better than David.',
          text:
            'My wife and I had a dream of downsizing into a small condo closer to where we work and play in Portland.',
          name: 'Alfredo Donin',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-2.png`),
        },
        {
          title: 'He keeps his client’s best interests in sharp focus.',
          text:
            'After working with David to sell my home in 2013, I was convinced that he’s the only realtor I’ll ever need.',
          name: 'Makenna Korsgaard',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-3.png`),
        },
        {
          title: 'It proved to be exactly the kind of home we wanted.',
          text:
            'We wish to express our thanks for your hard work in finding us a temporary home, which proved to be exactly what we wanted.',
          name: 'Jaydon Aminoff',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-1.png`),
        },
        {
          title: 'Nobody knows Portland and the peninsula better than David.',
          text:
            'My wife and I had a dream of downsizing into a small condo closer to where we work and play in Portland.',
          name: 'Alfredo Donin',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-2.png`),
        },
        {
          title: 'He keeps his client’s best interests in sharp focus.',
          text:
            'After working with David to sell my home in 2013, I was convinced that he’s the only realtor I’ll ever need.',
          name: 'Makenna Korsgaard',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-3.png`),
        },
        {
          title: 'It proved to be exactly the kind of home we wanted.',
          text:
            'We wish to express our thanks for your hard work in finding us a temporary home, which proved to be exactly what we wanted.',
          name: 'Jaydon Aminoff',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-1.png`),
        },
        {
          title: 'Nobody knows Portland and the peninsula better than David.',
          text:
            'My wife and I had a dream of downsizing into a small condo closer to where we work and play in Portland.',
          name: 'Alfredo Donin',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-2.png`),
        },
        {
          title: 'He keeps his client’s best interests in sharp focus.',
          text:
            'After working with David to sell my home in 2013, I was convinced that he’s the only realtor I’ll ever need.',
          name: 'Makenna Korsgaard',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-3.png`),
        },
        {
          title: 'It proved to be exactly the kind of home we wanted.',
          text:
            'We wish to express our thanks for your hard work in finding us a temporary home, which proved to be exactly what we wanted.',
          name: 'Jaydon Aminoff',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-1.png`),
        },
        {
          title: 'Nobody knows Portland and the peninsula better than David.',
          text:
            'My wife and I had a dream of downsizing into a small condo closer to where we work and play in Portland.',
          name: 'Alfredo Donin',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-2.png`),
        },
        {
          title: 'He keeps his client’s best interests in sharp focus.',
          text:
            'After working with David to sell my home in 2013, I was convinced that he’s the only realtor I’ll ever need.',
          name: 'Makenna Korsgaard',
          // eslint-disable-next-line global-require
          image: require(`@/assets/images/testimonials/image-3.png`),
        },
      ],
      
    }
  },
  computed: {
    authUIState() {
      return this.$store.state.ristic.authUIState
    },
    backgroundImageStyle() {
      return {
        borderRadius: 0,
        backgroundImage: `url(${this.heroImage})`,
        backgroundSize: 'cover', // This ensures the image covers the div
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Prevent the image from repeating
      };
    },
  },
  watch: {
    isLoggedIn: {
      handler(isLoggedIn){
        if (isLoggedIn){
          this.fetchFavoriteCars()
        }
      },
      immediate: true,
      deep: true
    },
    minPrice: {
      handler(v){
        this.localFilter.price_range[0] = v
      },
      immediate: true
    },
    maxPrice: {
      handler(v){
        this.localFilter.price_range[1] = v
      },
      immediate: true
    }
  },
  created() {
    this.fetchBestOffers()
    this.fetchCars("", false)
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);

    const paymentState = search.get('pmt_state');
    const paymentReference = search.get('txref') || search.get('reference')
    if (paymentState && paymentState === 'success' && paymentReference) {
      this.verifyPayment({ txref: paymentReference });
    }

    if (paymentState && paymentState === 'cancelled') {
      this.cancelPayment(paymentReference);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Payment Cancelled",
          icon: "InfoIcon",
          variant: "warning",
          text: "Payment cancelled, kindly try again later",
        },
      });
    }

    const passwordResetToken = search.get('password_reset_token');
    if (passwordResetToken) {
      this.initiatePasswordReset(passwordResetToken);
    }

    const options = {
      threshold: 0.5 // Trigger when 50% of the element is visible
    };
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    }, options);
    
    this.$refs.section1 && observer.observe(this.$refs.section1);
    this.$refs.section2 && observer.observe(this.$refs.section2);
    this.$refs.section3 && observer.observe(this.$refs.section3);
    this.$refs.section4 && observer.observe(this.$refs.section4);
    this.$refs.section5 && observer.observe(this.$refs.section5);
    this.$refs.section6 && observer.observe(this.$refs.section6);
    this.$refs.section7 && observer.observe(this.$refs.section7);
  },
  methods: {
    searchCars() {
      this.$router.push({ name: 'rentals', query: this.localFilter });
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/app-home.scss';

.star-icon {
  color: #f8d64e;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}

.slide-in {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.slide-in.show {
  opacity: 1;
  transform: translateX(0);
}

#car_brand_selector > div,
#car_type_selector > div,
#car_features_selector > div {
  border: none !important;
}

#home-page .form-control {
  border: none !important;
  margin-bottom: 10px;
}

#home-page .form-control:hover:not(.disabled):not(:disabled):not(:focus) {
  box-shadow: none !important;
}

#home-page .form-control:active, #home-page .form-control:focus {
  box-shadow: none !important;
}

span.brand-drop-down-image > span.b-avatar-img > img {
  object-fit: contain;
}

</style>
