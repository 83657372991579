<template>
  <div>
    <p style="">
      {{ title }}
      <span v-if="caret">
        <feather-icon icon="ChevronDownIcon" size="20" />
      </span>
    </p>

    <slot></slot>

    <hr style="border-width: 2px; margin: 0;" />
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      caret: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>
